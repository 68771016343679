<template>
  <div class="pagination">
    <a-config-provider :locale="zhCN">
      <a-pagination size="large"
                    :total="paginationList.total"
                    :page-size.sync="paginationList.size"
                    :current-page="paginationList.current"
                    :page-size-options="paginationList.pageSizeOptions"
                    :show-total="total => `共 ${total} 条`"
                    @showSizeChange="handleSizeChange"
                    @change="handleCurrentChange"
                    :hide-on-singl-page="true"
                    :show-size-changer="true"
                    :show-quick-jumper="true" />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: "pageination",
  data(){
    return {
      zhCN,
    }
  },
  props: {
    paginationList: {
      type: Object,
      required: true
    }
  },
  methods:{
    //每页条数变化
    handleSizeChange(current,pageSize){
      this.paginationList.size = pageSize
      this.paginationList.current = current // 显示条数变化时从第一页开始
      this.$emit('paging', this.paginationList)
    },
    //页数变化
    handleCurrentChange(current){
      this.paginationList.current = current
      this.$emit('paging', this.paginationList)
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  padding-top: 10px;
}
</style>