<template>
  <div class="senderInfo">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入姓名"
                      v-model.trim="queryParam.name"
                      clearable>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="queryParam.status"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 120px"
                            size="large"
                            placeholder="配送状态"
                            @change="senderStatusChange">
                    <a-select-option :key="0"
                                     :value="0">
                      未配送
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      配送中
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="searchSenderList">查询
                    </a-button>
                  </a-col>
                  <a-col :span="8" offset="8">
                    <a-button size="large" @click="resetSearchForm">重置</a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="sex" slot-scope="text">
          <span v-if="text===0">女</span>
          <span v-if="text===1">男</span>
        </template>
        <template slot="senderType" slot-scope="text,record">
          <span v-if="record.staffId">公司员工</span>
          <span v-else>第三方配送员</span>
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text===0" style="color: silver">未配送</span>
          <span v-if="text===1" style="color: #F29F2A">配送中</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <div>
            <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
              <a-button type="primary" size="small" icon="edit" @click="handleEdit(record)"
                        :disabled="record.status===1||record.staffId"/>
            </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="删除?"
                          :disabled="record.status===1"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => removeSender(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button  :disabled="record.status===1" type="danger" size="small" icon="delete"/>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
    </div>
    <div class="edit">
      <a-modal :visible="editVisible"
               okText="确定"
               cancelText="取消"
               @ok="handleOk"
               @cancel="handleCancel"
               width="400px">
        <template slot="title">
          <span style="font-weight: bolder;font-size: 20px;text-align: center">添加员工</span>
        </template>
        <a-row>
          <a-col :span="24">
            <label>姓名：</label>
            <a-input style="width: 200px"
                     placeholder="请输入"
                     v-model="currentSelectSender.name"/>
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="24">
            <label>性别：</label>
            <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                placeholder="请选择"
                type="text"
                style="width: 100px"
                v-model="currentSelectSender.sex"
                allowClear>
              <a-select-option :key="0"
                               :value="0">
                女
              </a-select-option>
              <a-select-option :key="1"
                               :value="1">
                男
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="24">
            <label>电话：</label>
            <a-input style="width: 200px"
                     placeholder="请输入"
                     v-model="currentSelectSender.name"/>
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="24">
            <label style="font-weight: bold;">备注：</label>
            <a-textarea placeholder="备注信息"
                        rows="4"
                        v-model="currentSelectSender.remark"
                        allowClear/>
          </a-col>
        </a-row>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Pageination from "@/components/pageination";
import {dateFormat} from "@/utils/common";

export default {
  name: "index",
  components: {
    Pageination,
  },
  inject:['reload'],
  created() {
    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getSenderList(queryParam);
  },
  data(){
    return{
      visible:false,
      editVisible:false,
      editable:false,
      staffRoles:this.$store.getters.userInfo.roleIds,
      userType:this.$store.getters.userInfo.staffType,
      searchForm: this.$form.createForm(this, {name: 'search_sender_form'}),
      selectedRowId:undefined,
      queryParam:{
        name:'',
        productId:undefined,
        phone:undefined,
        status:undefined,
      },
      senderId:undefined,
      currentSelectSender:{},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      dataSource:[],
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
          key:'name',
          width: '100px',
          align: 'center',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key:'sex',
          width: '40px',
          align: 'center',
          scopedSlots: { customRender: 'sex' },
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key:'phone',
          width: '120px',
          align: 'center',
        },
        {
          title: '配送员类型',
          dataIndex: 'senderType',
          key:'senderType',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'senderType' },
        },
        {
          title: '配送状态',
          dataIndex: 'status',
          key:'status',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '100px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        }
      ],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    handleCancel(){
      this.editVisible = false;
      this.currentSelectSender = {};
    },
    handleOk(){
      const selectItem = this.currentSelectSender;
      if(selectItem){
        this.$api.sender.updateSender(selectItem).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功！");
            this.reload();
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
      }
    },
    showModal(){
      this.visible = true;
    },
    handleEdit(record){
      this.currentSelectSender = JSON.parse(JSON.stringify(record));
      this.editable = true;
      this.editVisible = true;
    },
    searchSenderList(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getSenderList(queryParam);
    },
    resetSearchForm(){
      this.queryParam = {
        name: '',
        productId: undefined,
        phone: undefined,
        status: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getSenderList(params);
    },
    senderStatusChange(value) {
      this.queryParam.status = value;
    },
    removeSender(value){
      const params = {
        senderId:value,
      }
      this.$api.sender.removeById(params).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    getSenderList(params){
      if(this.userType===1){
        this.$api.sender.selectByCondition(params).then(res=>{
          if(res.code===200&&res.success){
            this.dataSource = res.data.records;
            this.paginationList.total = res.data.total;
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
      }
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getSenderList(queryParam);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  },
}
</script>

<style lang="scss" scoped>
.senderInfo{
  .item{
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }

}
</style>